import {
  SHOW_CTXMENU,
  SHOW_SIDEBAR,
  LARGE_SIDEBAR,
  SHOW_EVENTS,
  SHOW_THUMBNAIL_SEARCH,
  LOCK_EVENTS,
  LARGE_EVENTS,
  LARGE_THUMBNAIL,
  EVENTS_PANEL_MODE,
  EVENTS_PANEL_CELL_SIZE,
  EVENTS_PANEL_CELL_SIZE_LARGE,
  SHOW_PICTURES,
  SHOW_CALENDAR,
  SHOW_REC_PANEL,
  FETCH_EVENTS_PENDING,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  EVENT_ADDED,
  EVENTS_BATCH_ADDED,
  SHOW_SEARCH,
  SHOW_IN_POPUP,
  HIDE_POPUP,
  RESET_EVENTS,
  SERVER_DISCONNECT,
  SERVER_REMOVED,
  EVENTS_TABLE_MODE,
  MARK_FOR_REMOVAL,
  SHOW_GENERATE_EVENT_POPUP,
  CLOSE_GENERATE_EVENT_POPUP,
  LINKED_PERIPHERALS,
  CLOSE_LINKED_PERIPHERALS,
  EVENTS_SORT_BY_EVENT_TIME,
  EVENTS_SORT_AC_ORDER,
  MANAGEMENT_ACCORDION_OPEN,
  LOCK_THUMBS,
  SHOW_USEROPTIONS,
  EVENT_TABLE_DATA,
  ADD_NOTE,
  BLUR_EVENT,
  UPDATE_EVENT_THUMBNAIL,
  SET_ACTIVE_INDEX,
  SET_THUMB_TIMESTAMP,
  UPDATE_TRUTHY_STATUS,
  SERVER_DISCONNECT_ALL,
} from "../actions/types";
import { CamerasPrivacyBlur, Events } from "../helpers/serverCredentials";
import { getCurrentServerTime, serverTimeDiff } from "../helpers/timeHelpers";
import { getCLeanEventByArtecoId } from "./recordingsReducer";
import { OmniaLight, getServerByCodeName, isCredentialEnabled } from "./serversReducer";
import { sortEventsByEventTimeAC } from "../helpers/event";

const initialState = {
  pendingEvents: false,
  events: [],
  showEvents: false,
  showRecPanel: false,
  error: null,
  viewMode: 'live',
  eventTableData: []
};

function orderByLastUpdate(a, b) {
  if (a.lastUpdateTime > b.lastUpdateTime) {
    return -1;
  }
  if (a.lastUpdateTime < b.lastUpdateTime) {
    return 1;
  }
  return 0;
}

function mergeSortedArrays() {
  function merge(arrayOne, arrayTwo) {
    var totalLength = arrayOne.length + arrayTwo.length;
    var returnArray = new Array(totalLength);
    var iResult = 0;
    var iOne = 0;
    var iTwo = 0;
    for (var i = 0; i < totalLength; ++i) {
      if (iTwo < arrayTwo.length) {
        if (iOne >= arrayOne.length) {
          returnArray[i] = arrayTwo[iTwo++];
        } else if (arrayOne[iOne].lastUpdateTime > arrayTwo[iTwo].lastUpdateTime) {
          returnArray[i] = arrayOne[iOne++];
        } else {
          returnArray[i] = arrayTwo[iTwo++];
        }
      } else {
        returnArray[i] = arrayOne[iOne++];
      }
    }
    return returnArray;
  }
  var sortedArray = [];
  for (var i = 0; i < arguments.length; ++i) {
    sortedArray = merge(sortedArray, arguments[i]);
  }
  return sortedArray;
}

export function EventsReducer(state = initialState, action) {

  switch (action.type) {
    case SHOW_EVENTS:
      return {
        ...state,
        showEvents: action.payload,
        lockEvents: action.payload ? state.events.lockEvents : false
      }
      case SHOW_THUMBNAIL_SEARCH: 
      return {
        ...state,
        showThumbs: action.payload,
      }
    case MANAGEMENT_ACCORDION_OPEN:
      return {
        ...state,
        managementAccordionOpen: action.payload
      }
    case LARGE_SIDEBAR:
      return {
        ...state,
        largeSidebar: action.payload
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload
      }
    case SHOW_CTXMENU:
      return {
        ...state,
        showCtxMenu: action.payload
      }
     case SHOW_USEROPTIONS:
      return {
        ...state,
        showUserOptions: action.payload
      }

    case LOCK_EVENTS:
      return {
        ...state,
        lockEvents: action.payload
      }
      case LOCK_THUMBS:
        return {
          ...state,
          lockThumbs: action.payload
        }
      case LARGE_THUMBNAIL:
      return {
        ...state,
        largeThumbs: action.payload
      }
    case LARGE_EVENTS:
      return {
        ...state,
        largeEvents: action.payload
      }
    case EVENTS_PANEL_MODE:
      return {
        ...state,
        eventsPanelMode: action.payload
      }
    case EVENTS_PANEL_CELL_SIZE:
      return {
        ...state,
        eventsPanelCellSize: action.payload
      }
    case EVENTS_PANEL_CELL_SIZE_LARGE:
      return {
        ...state,
        eventsPanelCellSizeLarge: action.payload
      }
    case SHOW_PICTURES:
      return {
        ...state,
        showPictures: action.payload
      }
    case SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.payload
      }
    case SHOW_IN_POPUP:
      return {
        ...state,
        showPopup: true,
        popupData: action.payload
      }
    case HIDE_POPUP:
      return {
        ...state,
        showPopup: false,
        popupData: {}
      }
      case UPDATE_TRUTHY_STATUS:
    return {
        ...state,
        events: state.events.map(event =>
            event.artecoEventId === action.payload.eventId
                ? { ...event, isTruthy: action.payload.status } 
                : event
        )
    };
    case SHOW_CALENDAR:
      return {
        ...state,
        showCalendar: action.payload
      }

    case SHOW_REC_PANEL:
      return {
        ...state,
        showRecPanel: action.payload
      }
      case ADD_NOTE:
  return {
    ...state,
    notes: action.payload
  };
    case FETCH_EVENTS_PENDING:
      return {
        ...state,
        pendingEvents: true
      }
      case UPDATE_EVENT_THUMBNAIL:
  
          return {
            ...state,
            events: state.events.map(event => 
              event.id === action.payload.eventId
                ? { ...event, thumbnailUri: action.payload.thumbnailUri }
                : event
            )
          };
      case SET_ACTIVE_INDEX:
        return {
          ...state,
          activeIndices: {
            ...state.activeIndices,
            [action.payload.eventId]: action.payload.index,
          },
        };
        case SET_THUMB_TIMESTAMP:
      const { artecoEventId, thumbTimestamp } = action.payload;
      return {
        ...state,
        thumbTimestamps: {
          ...state.thumbTimestamps,
          [artecoEventId]: thumbTimestamp,
        },
      };
       
       
    case FETCH_EVENTS_SUCCESS:

      let events = Array.isArray(state.events) ? state.events : [];
      //let mergedEvents = Array.isArray(action.payload.events) && action.payload.events.length > 0 ? events.concat(action.payload.events) : events;

      let mergedEvents = Array.isArray(action.payload.events) && action.payload.events.length > 0 ? mergeSortedArrays(events, action.payload.events) : events;

      const key = 'id';
      const unique = [...new Map(mergedEvents.map(item => [item[key], item])).values()];

      return {
        ...state,
        pendingEvents: false,
        events: unique
      }

    case FETCH_EVENTS_ERROR:

      return {
        ...state,
        pendingEvents: false,
        error: action.error
      }
    case SERVER_DISCONNECT:
    case SERVER_REMOVED:

      let cleanEvents = state.events.filter(event => event.serverCodename !== action.payload.codeName);

      return {
        ...state,
        pendingEvents: false,
        events: cleanEvents
      }
    case SERVER_DISCONNECT_ALL:      

      return {
        ...state,
        pendingEvents: false,
        events: []
      }
    case EVENT_ADDED:

      let newEvent = action.payload;

      let updatedEvents = state.events;
      updatedEvents.unshift(newEvent);
      return {
        ...state,
        events: updatedEvents
      }

    case EVENTS_BATCH_ADDED:
      let newEvents = action.payload.events;
      let limit = action.payload.limit;


      const updatedBatchEvents = [...newEvents, ...state.events];
      // mergedArray have duplicates, lets remove the duplicates using Set

      let set = new Set();
      let unionEvents = updatedBatchEvents.filter(item => {
        if (!set.has(item.artecoEventId)) {
          set.add(item.artecoEventId);
          return true;
        }
        return false;
      }, set);

      //reorder sort by eventtTime newest first
      const sortByEventTime = true, sortAC = false;
      unionEvents.sort((a, b) => sortEventsByEventTimeAC(a, b, sortByEventTime, sortAC));

      //remove old events
      if (limit) {
        unionEvents = unionEvents.filter(event => {
          const eventTime = event.eventTime;
          const currentServerTime = getCurrentServerTime();

          const minutesDiff = serverTimeDiff(eventTime, currentServerTime, 'minutes');

          return minutesDiff < (60 * limit);
        })
      }

      return {
        ...state,
        events: unionEvents,
      }

    case RESET_EVENTS:

      return {
        ...state,
        events: [],
      }
    case MARK_FOR_REMOVAL:
      let markedEvents = state.events.filter(event => event.artecoEventId !== action.payload)
      return {
        ...state,
        events: markedEvents
      }
    case EVENTS_TABLE_MODE:
      return {
        ...state,
        viewMode: action.payload
      }
    case SHOW_GENERATE_EVENT_POPUP:
      return {
        ...state,
        showGenerateEventPopup: true,
        showGenerateEventPopupData: action.payload,
      }
    case CLOSE_GENERATE_EVENT_POPUP:
      return {
        ...state,
        showGenerateEventPopup: false,
        showGenerateEventPopupData: {},
      }
    case LINKED_PERIPHERALS:
      return {
        ...state,
        fetchLinkedPeripherals: true,
        getLinkedPeripherals: action.payload,
      }
    case CLOSE_LINKED_PERIPHERALS:
      return {
        ...state,
        fetchLinkedPeripherals: false,
        getLinkedPeripherals: [],
      }
    case EVENTS_SORT_BY_EVENT_TIME:
      return {
        ...state,
        eventsSortByEventTime: action.payload,
      }
    case EVENTS_SORT_AC_ORDER:
      return {
        ...state,
        eventsSortOrderAC: action.payload,
      }
    case EVENT_TABLE_DATA:
      return{
        ...state,
        eventTableData: action.payload,
      }
      case BLUR_EVENT:
        return{
          ...state,
          blurEvent : action.payload
      }
    default:
      return state;
  }
}


export const areEventsShown = (state) => {
  return state.events.showEvents ? state.events.showEvents : false;
}

export const isThumbsShown = (state) => {
  return state.events.showThumbs ? state.events.showThumbs : false;
}

export const isEventPanelLocked = (state) => {
  return state.events.lockEvents ? state.events.lockEvents : false;
}
export const isThumbPanelLocked = (state) => {
  return state.events.lockThumbs ? state.events.lockThumbs : false;
}


export const isEventPanelLarge = (state) => {
  return state.events.largeEvents ? state.events.largeEvents : false;
}
export const isThumbsPanelLarge = (state) => {
  return state.events.largeThumbs ? state.events.largeThumbs : false;
}
export const getActiveThumbIndex = (state, eventId) => {
  return state.events.activeIndices && state.events.activeIndices[eventId] !== undefined
    ? state.events.activeIndices[eventId]
    : null;
};
export const getActiveThumbtimestamp = (state, artecoEventId) => {
  return state.events.thumbTimestamps && state.events.thumbTimestamps[artecoEventId] !== undefined
    ? state.events.thumbTimestamps[artecoEventId]
    : null;
};


export const getEventsPanelMode = (state) => {
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return 'list';

  return state.events.eventsPanelMode ? state.events.eventsPanelMode : 'list';
}

export const getEventsPanelCellSize = (state) => {
  return state.events.eventsPanelCellSize ? state.events.eventsPanelCellSize : 1;
}

export const getEventsPanelCellSizeLarge = (state) => {
  return state.events.eventsPanelCellSizeLarge ? state.events.eventsPanelCellSizeLarge : 1;
}

export const arePicturesShown = (state) => { 
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return true;

  return state.events.showPictures ? state.events.showPictures : false;
}

export const isCtxMenuOpen = (state) => {
  return state.events.showCtxMenu !== undefined ? state.events.showCtxMenu : false;
}

export const isUserOptionsOpen =(state) => {
  return state.events.showUserOptions !== undefined ? state.events.showUserOptions : false;
}

export const isSidebarShown = (state) => {
  return state.events.showSidebar !== undefined ? state.events.showSidebar : true;
}

export const isSidebarLarge = (state) => {
  return state.events.largeSidebar !== undefined ? state.events.largeSidebar : false;
}

export const isSearchPanelShown = (state) => {
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return false;
  return state.events.showSearch ? state.events.showSearch : false;
}

export const isPopupShown = (state) => {
  return state.events.showPopup ? state.events.showPopup : false;
}


export const isCalendarPanelShown = (state) => {
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return false; 
  return state.events.showCalendar ? state.events.showCalendar : false;
}

export const isRecPanelShown = (state) => {
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return false;
  return state.events.showRecPanel ? state.events.showRecPanel : false;
}

export const isManagementAccordionOpen = (state) => {
  return state.events.managementAccordionOpen !== undefined ? state.events.managementAccordionOpen : false;
}


export const getEvents = (state, includeCategory300=false) => {
  let liveEvents = state.events.events.filter(event => {
    const server = getServerByCodeName(state, event.serverCodename);
    const hasCredential = isCredentialEnabled(Events, server);
    const isNotCattegory300 =  event.category !==300;

     if (includeCategory300) {
      return hasCredential && event;
    } else {
      return hasCredential && isNotCattegory300 && event;
    }
  });

  const sortByEventTime = areEventsSortByEventTime(state), sortAC = areEventsSortByAc(state);

  liveEvents.sort((a, b) => sortEventsByEventTimeAC(a, b, sortByEventTime, sortAC));
  return {
    ...state.events,
    //events: state.events.events.filter(event => event.visibility !== 'hidden')  
    events: liveEvents
  }
}
export const getEventsWithoutFalsey = (state) =>{
  const events = getEvents(state).events;
  return events.filter(event => event.isTruthy !== 2);
}
export const getEventStatus = (state, eventId) => {
  const events = getEvents(state)?.events;
  const event = events.find(event => event.artecoEventId === eventId);
  return event ? event.isTruthy : 0; 
};

export const getEventNotes = (state, artecoEventId) => {
  const events = getEvents(state);
  if (!artecoEventId) return 0;
  if (!events.events.length) return 0;

  const event = events.events.find(item => item.artecoEventId === artecoEventId);
  
  return event ? event.notes : [];
};

export const getEventsNum = (state) => {
  const events = getEvents(state).events;

  if (events) {
    const filteredEvents = events.filter(event => event.isTruthy !== 2);
    return filteredEvents.length;
  }

  return 0; 
};

export const getEventsPending = state => state.events.pendingEvents;
export const getEventsError = state => state.error;

export const getEventsNumForDevice = (state, artecoId) => {

  const events = getEvents(state);
  if (!artecoId) return 0;
  if (!events.events.length) return 0;

  return events.events.filter(item => item.artecoId == artecoId).length;

}

export const getEventsForDevice = (state, artecoId) => {

  const events = getEvents(state);
  if (!artecoId) return 0;
  if (!events.events.length) return 0;

  return events.events.filter(item => item.artecoId == artecoId);

}
export const getEventByTimeForDevice = (state, artecoId, eventTime) => {
  const eventsForDevice = getEventsForDevice(state, artecoId);
  
  if (!eventsForDevice.length) return null;

  return eventsForDevice.find(event => event?.eventTime === eventTime) || null;
};

export const getEventsForDevices = (state, artecoIdList) => {
  const events = getEvents(state);
  if (!artecoIdList.length) return {};
  if (!events.events.length) return {};

  const eventsForDevices = events.events.reduce(function (r, a) {
    r[a.artecoId] = r[a.artecoId] || [];
    r[a.artecoId].push(a);
    return r;
  }, Object.create(null));


  const out = {};
  artecoIdList.map(device => {
    const artecoId = device.artecoId;
    out[artecoId] = eventsForDevices[artecoId] || [];
  });

  return out;
}

export const getPopupData = state => state.events.popupData;

export const getTimeLineZoomLevel = state => state.events.zoomLevel;

export const getViewMode = (state) => {
  const isOmniaLight = OmniaLight(state);
  if (isOmniaLight) return 'live';
  return state.events.viewMode;
}

export const isGenerateEventPopupShown = (state) => {
  return state.events.showGenerateEventPopup ? state.events.showGenerateEventPopup : false;
}

export const getGenerateEventPopupData = state => state.events.showGenerateEventPopupData;


export const getLastEventForDevice = (state, artecoId) => {
  const events = getEventsForDevice(state, artecoId);

  return (events && Array.isArray(events) && events.length > 0) ? events[events.length - 1] : undefined
}

export const getEventByArtecoEventId = (state, artecoEventId) => {
  const events = getEvents(state);
  if (!artecoEventId) return null;
  if (!events.events.length) return null;

  const event = events.events.find(item => item.artecoEventId == artecoEventId);

  return event ? event : getCLeanEventByArtecoId(state, artecoEventId);
}

export const areEventsSortByEventTime = (state) => {
  return state.events.eventsSortByEventTime ? state.events.eventsSortByEventTime : false;
}
export const areEventsSortByAc = (state) => {
  return state.events.eventsSortOrderAC ? state.events.eventsSortOrderAC : false;
}

export const getEventTableData = (state) => {
  return state.events.eventTableData;
}

export const isblurEventActive = (state) =>{
  return state.events.blurEvent ? state.events.blurEvent : false;
}

export const getPrivacyBtnVisibility = (state) =>{
  const events = state.events.events;
  const eventsWithPrivacy = events.filter(event =>{
    const server = getServerByCodeName(state, event.serverCodename);
    const hasCredential = isCredentialEnabled(CamerasPrivacyBlur, server);
    const hasChPrivacy = event.eventData?.isChPrivacyEnable ;
    return hasCredential && hasChPrivacy;
  });
  const showBtn = eventsWithPrivacy?.length > 0 ? true : false;
  return showBtn;
}