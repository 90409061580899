import {  
  SHOW_ADD_SERVER_POPUP,
  SHOW_ADD_LAYOUT_POPUP,
  SHOW_EDIT_LAYOUT_POPUP,
  SHOW_SHARE_LAYOUT_POPUP,
  SHOW_EDIT_SERVER_POPUP,
  SHOW_POPUP_PROPERTIES_POPUP,
  CLOSE_SITES_INFORMATION_POPUP,
  SHOW_SITES_INFORMATION_POPUP,
  SHOW_COMING_SOON_POPUP,
  SHOW_EXPORT_FEATURES_POPUP,
  SHOW_ONVIF_DATA_POPUP,
  SHOW_THUMB_POPUP,
  THUMB_POPUP_ENHANCING_IS_PENDING,
  THUMB_POPUP_ENHANCING_IS_READY,
  DOWNLOAD_SSE_NOT_AVAILABLE,
  RESET_THUMB_DATA
} from "../actions/types";

const initialState = {
  features: [],
  showAddLayoutPopup: false,
  groupName: "",
  showComingSoonPopup: false,
  showEditLayoutPopup: false,
  showShareLayoutPopup: false,
  showEditServerPopup: false,
  showPopupPropertiesPopup: false,
  showExportFeaturesPopup: false,
  showOnvifDataPopup: false,
  showThumbPopup: false,
  editingLayout: null,
  sharingLayout: null,
  editingServer: null,
  showAddServerPopup: false,
  enhanceInProgress: false,
  showSitesInformationPopup: true,
};

export function FeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_COMING_SOON_POPUP:
      return {
        ...state,
        showComingSoonPopup: action.payload.showComingSoon        
      };
    case SHOW_ADD_LAYOUT_POPUP:
      return {
        ...state,
        showAddLayoutPopup: action.payload.showAddLayout,
        groupName: action.payload.groupName
      };
    case SHOW_ADD_SERVER_POPUP:
      return {
        ...state,
        showAddServerPopup: action.payload
      };
    case SHOW_EDIT_LAYOUT_POPUP:
      return {
        ...state,
        showEditLayoutPopup: action.payload.showEditLayout,
        editingLayout: action.payload.layout
      };
    case SHOW_SHARE_LAYOUT_POPUP:
      return {
        ...state,
        showShareLayoutPopup: action.payload.showShareLayout,
        sharingLayout: action.payload.layout
      };
    case SHOW_EDIT_SERVER_POPUP:
      return {
        ...state,
        showEditServerPopup: action.payload.showEditServer,
        editingServer: action.payload.server
      };
    case SHOW_POPUP_PROPERTIES_POPUP:
      return {
        ...state,
        showPopupPropertiesPopup: action.payload.showPopupProperties,
        editingLayout: action.payload.layout
      };
    case CLOSE_SITES_INFORMATION_POPUP:
      return{
        ...state,
        showSitesInformationPopup: false,
        serverNotAuthorzied:false,
        serverUsernameFiledEmpty: false,
        serverPasswordFieldEmpty:false,
      }
    case SHOW_SITES_INFORMATION_POPUP:
      return{
        ...state,
        showSitesInformationPopup: action.payload.showSitesInformationPopup,
        serverNotAuthorzied:action.payload.serverNotAuthorzied,
        serverUsernameFiledEmpty: action.payload.serverUsernameFiledEmpty,
        serverPasswordFieldEmpty: action.payload.serverPasswordFieldEmpty,
      }
    case SHOW_EXPORT_FEATURES_POPUP:
      return {
        ...state,
        showExportFeaturesPopup: action.payload.showExportFeaturesPopup,
        exportActionCallback: action.payload.actionCallback,
        exportDevices: action.payload.exportDevices,
        defaultData: action.payload.defaultData,
      };
    case SHOW_ONVIF_DATA_POPUP:
      return {
        ...state,
        showOnvifDataPopup: action.payload.showOnvifDataPopup,
        onvifData: action.payload.onvifData ? action.payload.onvifData : state.onvifData,
        currentObjectId: action.payload.currentObjectId ? action.payload.currentObjectId : state.currentObjectId,
      };
    case SHOW_THUMB_POPUP:
      return {
        ...state,
        showThumbPopup: action.payload.showThumbPopup,
        thumbData: action.payload.thumbData ? action.payload.thumbData : state.thumbData        
      };
    case THUMB_POPUP_ENHANCING_IS_PENDING:
      return {
        ...state,
        enhanceInProgress: true
      }
    case DOWNLOAD_SSE_NOT_AVAILABLE:
      return {
        ...state,
        enhanceInProgress: false
      }
    case THUMB_POPUP_ENHANCING_IS_READY: {
      return {
        ...state,
             enhanceInProgress: false,       
        thumbData: {
          ...state.thumbData,
          enhancedImageSrc: action.payload.enhancedImageSrc,                 
          enhanceImageDownloadUrl: action.payload.enhanceImageDownloadUrl,
          videoDownloadUrl: action.payload.videoDownloadUrl,
          imagePath: action.payload.imagePath,
          videoPath: action.payload.videoPath,
          origin: action.payload.origin
        }
      }
    }
    case RESET_THUMB_DATA: {
      return {
        ...state,
             enhanceInProgress: false, 
        thumbData: {}, 
      };

    }
      
    default:
      return state;
  }
}


export const isComingSoonPopupShown = (state) => {
  return state.features.showComingSoonPopup ? state.features.showComingSoonPopup : false;
}
export const isAddLayoutPopupShown = (state) => {
  return state.features.showAddLayoutPopup ? state.features.showAddLayoutPopup : false;
}

export const isEditLayoutPopupShown = (state) => {
  return state.features.showEditLayoutPopup ? state.features.showEditLayoutPopup : false;
}

export const isShareLayoutPopupShown = (state) => {
  return state.features.showShareLayoutPopup ? state.features.showShareLayoutPopup : false;
}
export const isExportFeaturesPopupShown = (state) => {
  return state.features.showExportFeaturesPopup ? state.features.showExportFeaturesPopup : false;
}
export const getExportActionCallback = (state) => {
  return state.features.exportActionCallback ? state.features.exportActionCallback : null;
}
export const isOnvifDataPopupShown = (state) => {
  return state.features.showOnvifDataPopup ? state.features.showOnvifDataPopup : false;
}
export const getOnvifData = (state) => {
  return state.features.onvifData ? state.features.onvifData : null;
}
export const getCurrentObjectId = (state) => {
  return state.features.currentObjectId ? state.features.currentObjectId : null;
}

export const getExportDevices = (state) => {
  return state.features.exportDevices || null;
}

export const getDefaultData = (state) => {
  return state.features.defaultData || null;
}

export const isEditServerPopupShown = (state) => {
  return state.features.showEditServerPopup ? state.features.showEditServerPopup : false;
}

export const isPopupPropertiesPopupShown = (state) => {
  return state.features.showPopupPropertiesPopup ? state.features.showPopupPropertiesPopup : false;
}

export const getEditingLayout = (state) => {
  return state.features.editingLayout ? state.features.editingLayout : null;
}

export const getSharingLayout = (state) => {
  return state.features.sharingLayout ? state.features.sharingLayout : null;
}

export const getEditingServer = (state) => {
  return state.features.editingServer ? state.features.editingServer : null;
}

export const getAddingLayoutGroupName = (state) => {
  return state.features.groupName ? state.features.groupName : "";
}

export const isAddServerPopupShown = (state) => {
  return state.features.showAddServerPopup ? state.features.showAddServerPopup : false;
}
export const isThumbPopupShown = (state) => {
  return state.features.showThumbPopup ? state.features.showThumbPopup : false;
}
export const getThumbData = (state) => {
  return {...state.features.thumbData} ? {...state.features.thumbData} : null;
}
export const isEnhanceInProgress = (state) => {
  return state.features.enhanceInProgress;
}

export const showSitesInformationPopup = (state) => {
  return {
    showSitesInformationPopup: state.features.showSitesInformationPopup,
    serverNotAuthorzied: state.features.serverNotAuthorzied || false,
    serverUsernameFiledEmpty: state.features.serverUsernameFiledEmpty || false,
    serverPasswordFieldEmpty: state.features.serverPasswordFieldEmpty || false,
  }
}
