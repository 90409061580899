import {
  FETCH_USERPREFS_PENDING,
  FETCH_USERPREFS_SUCCESS,
  FETCH_USERPREFS_ERROR,
  USERPREFS_UPDATED,
  ENDPOINT_CHECK_RESULT,
  FETCH_DOWNLOADS_ERROR,
  ENDPOINT_CHECKING,
  DOWNLOAD_SSE_NOT_AVAILABLE,
  ACTIVE_TAB

} from "../actions/types";

const initialState = {
  userPrefs: {},  
  error: null,
  pendingUserPrefs: false,
  checkingEndpoint: false,
  activeTab: 'account',
  exportFeatures: []
};

const isEmpty = require("is-empty");

export function UserPrefsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERPREFS_SUCCESS:
    case USERPREFS_UPDATED:

      const defaultUserPrefs = {
        exportServiceEndpoint: false,
        exportServiceChecked: false,
        exportServiceEnabled: false,
        visible: false,
        enableNotifications: false,
        googleMapThemeId: 0,
        googleMapType: "roadmap",
        googleMapTypeSelectorHidden: false,
        audioMsgs: false,
        audioCall: false,
        audioEvts: false,
        audioToast: false,
        trafficLayerEnabled: false,
        showDropdown: false,
        showLiveVideo: false,
        showGroups: false,
        showLiveMeta: false,
        cloudBackup: false,
        allowTabReordering: false,
        timeOfRetrieval: 1,
        exportServiceFeatures: []
      }

      const currentUserPrefs = isEmpty(state.userPrefs) ? defaultUserPrefs : state.userPrefs;
      let updatedUserPrefs = {...currentUserPrefs, ...action.payload};
       updatedUserPrefs.showGroups = false //do not show; show groups option in layouts for all users 

      return {
        ...state,
        pendingUserPrefs: false,
        checkingEndpoint: false,
        userPrefs: updatedUserPrefs,
      }
    case ENDPOINT_CHECKING:
      return {
        ...state,
        checkingEndpoint: true
      }
    case ENDPOINT_CHECK_RESULT:

      const userPrefsChecked = {
        ...state.userPrefs
      }

      userPrefsChecked.exportServiceChecked = action.payload.result;

      return {
        ...state,
        pendingUserPrefs: false,
        checkingEndpoint: false,
        userPrefs: userPrefsChecked,
        exportServiceFeatures: action.payload.features
      }
    case FETCH_USERPREFS_PENDING:
      return {
        ...state,
        pendingUserPrefs: true
      };
    case FETCH_USERPREFS_ERROR:
      return {
        ...state,
        error: action.error,
        pendingUserPrefs: false
      }
    case FETCH_DOWNLOADS_ERROR:
      const userPrefsDisabledService = {
        ...state.userPrefs
      }

      userPrefsDisabledService.exportServiceChecked = false;
      userPrefsDisabledService.exportServiceAlert = true;
      return {
        ...state,        
        userPrefs: userPrefsDisabledService
      }
    case DOWNLOAD_SSE_NOT_AVAILABLE:
      const enhanceNotAvailableSettings = [...state.userPrefs.exportServiceFeatures];
      const index = enhanceNotAvailableSettings.findIndex(item => item.hasOwnProperty('thumbs'));

      if (index !== -1) {
        enhanceNotAvailableSettings[index].thumbs = false;
      }

      return {
        ...state,
        userPrefs : {
          ...state.userPrefs,
          exportServiceFeatures: enhanceNotAvailableSettings
        }
      }
      case ACTIVE_TAB:
        return {
          ...state,
          activeTab: action.payload
        }
    default:
      return state;
  }
};



export const getUserPrefs = state => state.userPrefs.userPrefs;
export const getUserPrefsPending = state => state.userPrefs.pendingUserPrefs;
export const getEndpointChecking = state => {
  return state.userPrefs.checkingEndpoint;
}
export const getUserPrefsError = state => state.error;

export const getAvailableExportFeatures = state => {  
  return state.userPrefs.userPrefs.exportServiceFeatures;
}

export const isExportPrivacySupported = state => {
  
  let supported = false;
  const supportedExportFeatures = getAvailableExportFeatures(state); 
  const hasPrivacy = supportedExportFeatures && supportedExportFeatures.find(feature => feature.metadata);
  if (hasPrivacy && hasPrivacy.metadata.includes('privacy')) {
    supported = true;
  }
  return supported;
}

export const isThumbEnhancementSupported = state => {
  let supported = false;
  const supportedExportFeatures = getAvailableExportFeatures(state); 
  supportedExportFeatures && supportedExportFeatures.map(feature => {
    const hasThumbs = feature.thumbs;                        
    if(hasThumbs) {
      supported =  true;          
    } 
  })
  
  return supported;
}

export const isTimestampAndWatermarkSupported = state => {
  
  let supported = false;
  const supportedExportFeatures = getAvailableExportFeatures(state); 
  const hasMetadata = supportedExportFeatures && supportedExportFeatures.find(feature => feature.metadata);
  if (hasMetadata && hasMetadata.metadata.includes('watermark')) {
    supported = true;
  }

  if (hasMetadata && hasMetadata.metadata.includes('timestamp')) {
    supported = true;
  }

  return supported;
}

export const getActiveTab = state =>{
  return state.userPrefs.activeTab
}