// **** Peripherals Web Socket payload

export function wsSetPeripheralPayload(id, value) {
  const request = {
    "lane": "setPeripheral",
    "data":
    {
      "ctx": "setPeripheral",
      "id": parseInt(id),
      "value": parseInt(value)
    }
  }
  return JSON.stringify(request);
}

export function wsGetGetPeripheralPayload(id) {
  const request = {
    "lane": "getPeripheral",
    "data":
    {
      "ctx": "getPeripheral",
      "id": parseInt(id)
    }
  }
  return JSON.stringify(request);
}

// **** Event websocket payload


export function wsLiveEventPayLoad(cameraId, param, category, image, imageW, imageH) {

  const defaultCatValue = 201;

  const data = {
    "ctx": "liveEvent",
    "chId": parseInt(cameraId),
    "param": param,
    "cat": category ? parseInt(category) : defaultCatValue,
  }

  if (image != undefined) {
    data.width = parseInt(imageW);
    data.height = parseInt(imageH);
    data.image = image;
  }

  var request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}

// **** Custom events Web Socket payload

export function wsOldEventPayLoad(cameraId, param, category, startTime, endTime, color, image, imageW, imageH) {

  const defaultCatValue = 201;

  const data = {
    "ctx": "oldEvent",
    "chId": cameraId,
    "param": param,
    "cat": category ? parseInt(category) : defaultCatValue,
    "start": startTime,
  }

  if (endTime != undefined) {
    data.end = endTime;
  }

  if (color != undefined) {
    data.color = color.replace("#", ""); // non mandare # al server
  }

  if (image != undefined) {
    data.width = parseInt(imageW);
    data.height = parseInt(imageH);
    data.image = image;
  }
  var request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}

export function wsEditEventPayLoad(eventId, state, color, favourite) {

  const data = {
    "ctx": "editEvent",
    "eventId": eventId,
  }

  if (state != undefined) {

    data.state = parseInt(state);
  }

  if (color != undefined) {
    data.color = color.replace("#", ""); // non mandare # al server
  }

  if (favourite != undefined) {
    const favouriteAsInt = favourite == true ? 1 : 0;
    data.favourite = favouriteAsInt;
  }

  const request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}

export function wsEditEventEndTimePayLoad(eventId, endTime) {

  const data = {
    "ctx": "editStop",
    "eventId": parseInt(eventId),
    "endTime": endTime,
  }

  const request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}

export function wsProcessEventPayLoad(eventId, username) {

  const data = {
    "ctx": "processEvent",
    "eventId": parseInt(eventId),
    "username": username,
  }

  const request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}

export function wsAddNoteToEventPayLoad(eventId, noteText, username) {

  const data = {
    "ctx": "addNote",
    "eventId": parseInt(eventId),
    "username": username,
    "noteText": noteText,
  }

  const request = {
    "lane": "manageEvent",
    "data": data,
  };

  return JSON.stringify(request);
}
export function wsAcknowledgeEventPayLoad(eventId, username, truthyFalsey){
  const data = {
    "ctx": "setTruthy",
    "eventId": parseInt(eventId),
    "username": username,
    "isTruthy": truthyFalsey,
  }
  const request = {
    "lane": "manageEvent",
    "data": data,
  }
  return JSON.stringify(request)
}

export function wsControlPTZPayload(id, action, speed, preset = null, start_sequence = null) {
  const request = {
    "lane": "manageDevices",
    "data":
    {
      "ctx": "ptzCmd",
      "action": action,
      "chId": parseInt(id),
      "speed": parseInt(speed),
      "par1": action === "start_sequence" ? parseInt(start_sequence) : action === "preset" ? parseInt(preset) : undefined
    }
  }
  return JSON.stringify(request);
}

export function wsGetPtzInfoPayload() {
  const request = {
    "lane": "manageDevices",
    "data":
    {
      "ctx": "ptzInfoPresetSequence"      
    }
  }
  return JSON.stringify(request);
}

export function wsConfigureChannelPayload(id, lat, long, artecoId) {
  const request = {
    "lane": "manageDevices",
    "data" :{
      "ctx" : "chlSetConfig",
      "chId" : parseInt(id),
      "lat" : parseFloat(lat),
      "long" : parseFloat(long),
      "artecoId": artecoId
  }
  }
  return JSON.stringify(request);
}

export function wsConfigureCreateChannel(ip,port, rtspPort,description, username, password, portocol, atcSrcType, lat, long) {
  const request = {
    "lane": "manageDevices",
    "data": {
      "ctx": "chAddNew",
      "ip-address": ip,
      "port": parseInt(port),
      "rtspPort": parseInt(rtspPort),
      "description": description,
      "username": username,
      "password": password,
      "protocol": portocol,
      "atcSrcType": atcSrcType,
      "lat" : parseFloat(lat),
      "long" : parseFloat(long),
    }
  }
  return JSON.stringify(request)
}
export function wsGetPrivacyDataPayload(id) {
  const request = {
    "lane": "manageDevices",
    "data":
    {
      "ctx": "getPrivacyLive",
      "chId": parseInt(id)    
    }
  }
  return JSON.stringify(request)
}