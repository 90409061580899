import { getLanAddress } from '../helpers/network';
import { getMeta } from '../helpers/server';
import {
  SHOW_ADD_LAYOUT_POPUP,
  SHOW_ADD_SERVER_POPUP,
  SHOW_EDIT_LAYOUT_POPUP,  
  SHOW_SHARE_LAYOUT_POPUP,
  SHOW_EXPORT_FEATURES_POPUP,
  SHOW_ONVIF_DATA_POPUP,
  SHOW_EDIT_SERVER_POPUP,
  SHOW_POPUP_PROPERTIES_POPUP,
  SHOW_THUMB_POPUP,
  CLOSE_SITES_INFORMATION_POPUP,
  SHOW_SITES_INFORMATION_POPUP,
  SHOW_CHAT_LIST,
  SHOW_COMING_SOON_POPUP,
  RESET_THUMB_DATA
} from './types';
import LZString from 'lz-string';

export const showAddLayoutPopup = (showAddLayout, groupName) => dispatch => {  
  dispatch({
    type: SHOW_ADD_LAYOUT_POPUP,
    payload: {
      showAddLayout,
      groupName
    }
  })
}

export const showEditLayoutPopup = (showEditLayout, layout = null) => dispatch => {  
  dispatch({
    type: SHOW_EDIT_LAYOUT_POPUP,
    payload: {
      showEditLayout: showEditLayout, 
      layout: layout
    }
  })
}

export const showComingSoonPopup = (showComingSoon) => dispatch => {  
  dispatch({
    type: SHOW_COMING_SOON_POPUP,
    payload: {
      showComingSoon: showComingSoon      
    }
  })
}

export const showShareLayoutPopup = (showShareLayout, layout = null) => dispatch => {  
  dispatch({
    type: SHOW_SHARE_LAYOUT_POPUP,
    payload: {
      showShareLayout: showShareLayout, 
      layout: layout
    }
  })
}

export const showExportFeaturesPopup = (showExportFeaturesPopup, actionCallback, exportDevices, defaultData) => dispatch => {  
  

  dispatch({
    type: SHOW_EXPORT_FEATURES_POPUP,
    payload: {
      showExportFeaturesPopup: showExportFeaturesPopup,      
      actionCallback: actionCallback,
      exportDevices: Array.isArray(exportDevices) ? exportDevices : [],
      defaultData
    }
  })
}

export const showOnvifDataPopup = (showOnvifDataPopup, server, requestData = null) => dispatch => {  
  if(showOnvifDataPopup) {

    //show popup right after the click
    dispatch({
      type: SHOW_ONVIF_DATA_POPUP,
      payload: {
        showOnvifDataPopup: true,   
        currentObjectId: requestData.objectId       
      }
    })

    if(!requestData || !requestData.isSelected) {

      const serverIp = server.isLocal ? getLanAddress(server.ip) : server.ip;            
      const aggregationQuery = [
          {
              '$match': {
                  'ObjectId': requestData.objectId,
                  'ChannelId': requestData.deviceId
              }
          }, {
              '$group': {
                  '_id': '$ObjectId',
                  'min_timeStamp': {
                      '$min': '$FrameTime.UnixMillis'
                  },
                  'max_timeStamp': {
                      '$max': '$FrameTime.UnixMillis'
                  }
              }
          }, {
              '$lookup': {
                  'from': 'events',
                  'localField': '_id',
                  'foreignField': 'ObjectId',
                  'let': {
                      'minTs': '$min_timeStamp',
                      'maxTs': '$max_timeStamp'
                  },
                  'pipeline': [
                      {
                          '$match': {
                              '$expr': {
                                  '$and': [
                                      {
                                          '$eq': [
                                              '$ObjectId', requestData.objectId
                                          ]
                                      }, {
                                          '$eq': [
                                              '$ChannelId', requestData.deviceId
                                          ]
                                      }, {
                                          '$or': [
                                              {
                                                  '$eq': [
                                                      '$FrameTime.UnixMillis', '$$minTs'
                                                  ]
                                              }, {
                                                  '$eq': [
                                                      '$FrameTime.UnixMillis', '$$maxTs'
                                                  ]
                                              }
                                          ]
                                      }
                                  ]
                              }
                          }
                      }
                  ],
                  'as': 'filteredEvents'
              }
          }, {
              '$project': {
                  '_id': 1,
                  'min_timeStamp': 1,
                  'max_timeStamp': 1,
                  'firstDocument': {
                      '$arrayElemAt': [
                          '$filteredEvents', 0
                      ]
                  },
                  'lastDocument': {
                      '$arrayElemAt': [
                          '$filteredEvents', -1
                      ]
                  }
              }
          }
      ];


      const metaDetailRequest = {
        "serverProtocol": server.protocol,
        "serverIp": serverIp,
        "serverPort": server.port,
        "sessionId": server.sessionId,
        "access_token": server.access_token,
        "chId": requestData.deviceId,    
        "server": server,
        "method": 'post',        
        "aggregation": {          
          "aggregation": JSON.stringify(aggregationQuery)
        }
      }
    
      getMeta(metaDetailRequest)
      .then((response) => {
        const playlistResponse = response.root && response.root.info;
        const meta = playlistResponse && JSON.parse(LZString.decompressFromEncodedURIComponent(playlistResponse.onvifMetadataHistory));

        const found = meta && meta[0] && meta[0].metadata[0];
        
        if(found) {
          const firstMeta = meta[0].metadata[0].firstDocument;
          const lastMeta = meta[0].metadata[0].lastDocument;
          const onvifData = {
            ...firstMeta,
            objectId: requestData.objectId,
            imgUrl: requestData.imgUrl,        
            artecoId: requestData.artecoId,
            popupMode: requestData.popupMode,
            lastOccurrence: lastMeta,
            showActionsInOnvifPopup: requestData.showActionsInOnvifPopup
          }
    
          dispatch({
            type: SHOW_ONVIF_DATA_POPUP,
            payload: {
              showOnvifDataPopup: showOnvifDataPopup,
              onvifData: onvifData,
              currentObjectId: requestData.objectId 
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
      })
    }
    
  } else {
    dispatch({
      type: SHOW_ONVIF_DATA_POPUP,
      payload: {
        showOnvifDataPopup: showOnvifDataPopup        
      }
    })
  }

  // dispatch({
  //   type: SHOW_ONVIF_DATA_POPUP,
  //   payload: {
  //     showOnvifDataPopup: showOnvifDataPopup,
  //     onvifData: onvifData
  //   }
  // })
}

export const showEditServerPopup = (showEditServer, server = null) => dispatch => {  
  dispatch({
    type: SHOW_EDIT_SERVER_POPUP,
    payload: {
      showEditServer: showEditServer, 
      server: server
    }
  })
}

export const showPopupPropertiesPopup = (showPopupProperties, layout = null) => dispatch => {  
  dispatch({
    type: SHOW_POPUP_PROPERTIES_POPUP,
    payload: {
      showPopupProperties: showPopupProperties, 
      layout: layout
    }
  })
}

export const showAddServerPopup = (showAddServer) => dispatch => {  
  dispatch({
    type: SHOW_ADD_SERVER_POPUP,
    payload: showAddServer
  })
}

export const showThumbPopup = (showThumbPopup, thumbData) => dispatch => {  
  dispatch({
    type: SHOW_THUMB_POPUP,
    payload: {
      showThumbPopup: showThumbPopup,      
      thumbData: thumbData
    }
  })
}
export const resetThumbData = () => dispatch => {
  dispatch({
    type: RESET_THUMB_DATA,
  })
};
export const closeSitesInformationPopup = () => dispatch => {
  dispatch({
    type: CLOSE_SITES_INFORMATION_POPUP,
  })
}

export const showSiteInformationPopup = (showSitesInformationPopup,serverNotAuthorzied,serverUsernameFiledEmpty,serverPasswordFieldEmpty) => dispatch => {
  dispatch({
    type: SHOW_SITES_INFORMATION_POPUP,
    payload:{
      showSitesInformationPopup,
      serverNotAuthorzied,
      serverUsernameFiledEmpty,
      serverPasswordFieldEmpty,
    }
  })
}