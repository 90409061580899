import React, { Component } from 'react'
import { artecoVars } from '../../config/MUIThemes';
import { Check as CheckedIcon } from 'react-feather';
import { X as UncheckedIcon } from 'react-feather';

import Switch from "react-switch";

class ArtecoSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked || false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleChange = (checked) => {
    const { onChange, id } = this.props;
    this.setState({ checked }, () => {
      onChange && onChange(checked, id);
    });
  }

  render() {
    const { checked } = this.state;
    const {eventPopupLive} = this.props;
    const CheckIcon = (<CheckedIcon className='arteco-switch-icons arteco-switch-check-icon' />);
    const UncheckIcon = (<UncheckedIcon className='arteco-switch-icons arteco-switch-uncheck-icon' />);

    return (
      <Switch        
        onChange={this.handleChange}
        className={`arteco-switch ${checked ? 'checked' : ''}`}
        checked={this.state.checked}
        handleDiameter={12}
        onHandleColor={artecoVars.chat_icon_green}
        offHandleColor={eventPopupLive? artecoVars.chat_icon_green: artecoVars.red_highlight}
        height={16}
        width={32}
        borderRadius={16}
        offColor={eventPopupLive? artecoVars.reskin_grey_3 :artecoVars.hover_background}
        onColor={eventPopupLive? artecoVars.reskin_grey_3 :artecoVars.hover_background}
        checkedIcon={eventPopupLive? '': CheckIcon}
        uncheckedIcon={eventPopupLive? '': UncheckIcon}
        disabled={this.props.disabled}
      />
    )
  }
}

export default ArtecoSwitch;
