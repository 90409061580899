
//errors
export const SET_ERRORS = "SET_ERRORS";

//users
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_INFO_UPDATE = "USER_INFO_UPDATE";
export const UPDATE_USER_SITES = 'UPDATE_USER_SITES';
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const UPDATE_USER_POSITION = 'UPDATE_USER_POSITION';

//servers
export const SERVER_ADDED = 'SERVER_ADDED';
export const SERVER_REMOVED = 'SERVER_REMOVED';
export const SERVERS_REMOVED = 'SERVERS_REMOVED';
export const DUPLICATE_SERIAL_SERVER_REMOVE = 'DUPLICATE_SERIAL_SERVER_REMOVE'
export const SERVER_UPDATED = 'SERVER_UPDATED';
export const SERVER_UPDATE_USER_LIST = 'SERVER_UPDATE_USER_LIST';
export const SERVERS_UPDATE_USER_LIST = 'SERVERS_UPDATE_USER_LIST';
export const SET_CURRENT_SERVER = 'SET_CURRENT_SERVER';
export const AUTHENTICATION_ERROR='AUTHENTICATION_ERROR';
export const GET_SERVERS = "GET_SERVERS";
export const UPDATE_SERVER = "UPDATE_SERVER";
export const SET_PERIPHERAL = "SET_PERIPHERAL";
export const INIT_PERIPHERAL = "INIT_PERIPHERAL";
export const EMPTY_PASSWORD_SERVER_UDPATE = "EMPTY_PASSWORD_SERVER_UPDATE";
export const STORE_SITES = "STORE_SITES";
export const FETCH_SITES_PENDING = "FETCH_SITES_PENDING";
export const RESET_AUTHENTICATION_ERROR = "RESET_AUTHENTICATION_ERROR";
export const UPDATE_TIME_MISMATCH_SERVER  = "UPDATE_TIME_MISMATCH_SERVER ";

export const FETCH_SERVERS_PENDING = 'FETCH_SERVERS_PENDING';
export const FETCH_SERVERS_SUCCESS = 'FETCH_SERVERS_SUCCESS';
export const FETCH_SERVERS_ERROR = 'FETCH_SERVERS_ERROR';
export const FETCH_SERVERS_UPDATE = 'FETCH_SERVERS_UPDATE';

export const SERVER_INFO = 'SERVER_INFO';
export const SERVER_CONNECTNG = 'SERVER_CONNECTNG';
export const FORCE_LOGIN = 'FORCE_LOGIN';
export const STOP_LOGIN = 'STOP_LOGIN';
export const FORCE_LOGIN_CODENAME = 'FORCE_LOGIN_CODENAME';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const FORCE_LOGOUT_CODENAME = 'FORCE_LOGOUT_CODENAME';
export const FORCE_LOGIN_ALL = 'FORCE_LOGIN_ALL';
export const SERVER_TREE_TOGGLE = 'SERVER_TREE_TOGGLE';
export const SERVER_TREE_TOGGLE_ALL = 'SERVER_TREE_TOGGLE_ALL';
export const SET_SERVER_IS_LOCAL = 'SET_SERVER_IS_LOCAL';
export const SET_SERVER_IS_OFFLINE = 'SET_SERVER_IS_OFFLINE';
export const SERVER_DISCONNECT = "SERVER_DISCONNECT";
export const SERVER_DISCONNECT_ALL = "SERVER_DISCONNECT_ALL";
export const FILTER_SERVERS_WITHOUT_LICENCE = 'FILTER_SERVERS_WITHOUT_LICENCE'

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const ABORT_LOGIN = 'ABORT_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//layouts
export const LAYOUT_REMOVED = 'LAYOUT_REMOVED';
export const LAYOUT_TOGGLED = 'LAYOUT_TOGGLED';
export const LAYOUTS_TOGGLED = 'LAYOUTS_TOGGLED';
export const LAYOUTS_REMOVED = 'LAYOUTS_REMOVED';
export const LAYOUT_ADDED = 'LAYOUT_ADDED';
export const LAYOUT_UPDATED = 'LAYOUT_UPDATED';
export const ACTIVE_LAYOUT_UPDATE = "ACTIVE_LAYOUT_UPDATE";
export const LAYOUT_REFRESHED = 'LAYOUT_REFRESHED';
export const LAYOUT_UPDATED_CHANNEL = 'LAYOUT_UPDATED_CHANNEL';
export const LAYOUT_UPDATED_SESSION = 'LAYOUT_UPDATED_SESSION';
export const NO_ACTIVE = 'NO_ACTIVE';
export const LAYOUT_SELECT_CHANNEL = 'LAYOUT_SELECT_CHANNEL';
export const LAYOUT_UNSELECT_CHANNEL = 'LAYOUT_UNSELECT_CHANNEL';
export const LAYOUT_RESET_INCLUDED_CAMERAS = 'LAYOUT_RESET_INCLUDED_CAMERAS';

export const GET_LAYOUTS = "GET_LAYOUTS";
export const FETCH_LAYOUTS_PENDING = 'FETCH_LAYOUTS_PENDING';
export const LAYOUTS_UPDATING = 'LAYOUTS_UPDATING';
export const FETCH_LAYOUTS_SUCCESS = 'FETCH_LAYOUTS_SUCCESS';
export const FETCH_LAYOUTS_ERROR = 'FETCH_LAYOUTS_ERROR';
export const SET_LAYOUT_VISUAL_MODE = 'SET_LAYOUT_VISUAL_MODE';
export const SET_LAYOUT_LOCK = 'SET_LAYOUT_LOCK';
export const SHOW_ONLY_IN_LAYOUT = 'SHOW_ONLY_IN_LAYOUT';
export const LAYOUT_INFO = 'LAYOUT_INFO';
export const LAYOUT_RENAME = 'LAYOUT_RENAME';
export const RESET_LAYOUT_GRID = 'RESET_LAYOUT_GRID';
export const LAYOUT_ZOOM_UPDATED = 'LAYOUT_ZOOM_UPDATED';
export const FETCH_USERLIST_PENDING = 'FETCH_USERLIST_PENDING';
export const FETCH_USERLIST_SUCCESS = 'FETCH_USERLIST_SUCCESS';
export const FETCH_USERLIST_ERROR = 'FETCH_USERLIST_ERROR';


export const SET_CHANNEL_MODE = 'SET_CHANNEL_MODE';
export const SET_CHANNELS_MODE = 'SET_CHANNELS_MODE';
export const RESET_CHANNEL_MODE = 'RESET_CHANNEL_MODE';

export const CHECK_LAYOUT_SOURCES = 'CHECK_LAYOUT_SOURCES';
export const TOGGLE_COVER_MODE = 'TOGGLE_COVER_MODE';
export const UPDATE_LAYOUTS_ORDER = 'UPDATE_LAYOUTS_ORDER';

//devices
export const DEVICE_INFO = 'DEVICE_INFO';
export const DEVICE_INFO_RESET = "DEVICE_INFO_RESET";

//events
export const SHOW_EVENTS = 'SHOW_EVENTS';
export const SHOW_THUMBNAIL_SEARCH= 'SHOW_THUMBNAIL_SEARCH';
export const LOCK_EVENTS = 'LOCK_EVENTS';
export const LOCK_THUMBS = 'LOCK_THUMBS';
export const LARGE_EVENTS = 'LARGE_EVENTS';
export const LARGE_THUMBNAIL = 'LARGE_THUMBS';
export const SHOW_PICTURES = 'SHOW_PICTURES';
export const SHOW_SEARCH = 'SHOW_SEARCH';
export const SHOW_CALENDAR = 'SHOW_CALENDAR';
export const SHOW_REC_PANEL = 'SHOW_REC_PANEL';
export const FETCH_EVENTS_PENDING = 'FETCH_EVENTS_PENDING';
export const SERVER_EVENTS_PENDING_REQUEST = 'SERVER_EVENTS_PENDING_REQUEST';
export const SERVER_EVENTS_REQUEST_COMPLETE = 'SERVER_EVENTS_REQUEST_COMPLETE';
export const SERVER_EVENTS_REQUEST_ERROR = 'SERVER_EVENTS_REQUEST_ERROR';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR';
export const EVENT_ADDED = 'EVENT_ADDED';
export const TAG_LAST_TIMESTAMP = 'TAG_LAST_TIMESTAMP';
export const EVENTS_BATCH_ADDED = 'EVENTS_BATCH_ADDED';
export const RESET_EVENTS = 'RESET_EVENTS';
export const SERVER_UPDATE_SOCKET_STATUS = 'SERVER_UPDATE_SOCKET_STATUS';
export const MARK_FOR_REMOVAL = 'MARK_FOR_REMOVAL';
export const EVENTS_PANEL_MODE = 'EVENTS_PANEL_MODE';
export const EVENTS_PANEL_CELL_SIZE = 'EVENTS_PANEL_CELL_SIZE';
export const EVENTS_PANEL_CELL_SIZE_LARGE = 'EVENTS_PANEL_CELL_SIZE_LARGE';
export const EVENTS_SORT_BY_EVENT_TIME = 'EVENTS_SORT_BY_EVENT_TIME';
export const EVENTS_SORT_AC_ORDER = 'EVENTS_SORT_AC_ORDER';
export const EVENT_TABLE_DATA = 'EVENT_TABLE_DATA';
export const ADD_NOTE = 'ADD_NOTE';
export const BLUR_EVENT = 'BLUR_EVENT';
export const ENHANCE_IMAGE = 'ENHANCE_IMAGE';
export const UPDATE_EVENT_THUMBNAIL = 'UPDATE_EVENT_THUMBNAIL';


//events - popup
export const SHOW_IN_POPUP = 'SHOW_IN_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';
export const SET_THUMB_TIMESTAMP = 'SET_THUMB_TIMESTAMP';
export const UPDATE_TRUTHY_STATUS = 'UPDATE_TRUTHY_STATUS';
//autodelete
export const CLEAN_DONE = 'CLEAN_DONE';
// recordings
export const SHOW_REC_SEARCH_POPUP = 'SHOW_REC_SEARCH_POPUP'; // search rec
export const HIDE_REC_SEARCH_POPUP = 'HIDE_REC_SEARCH_POPUP'; // search rec

//filters
export const SHOW_FILTERS = 'SHOW_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const GET_FILTERS = 'GET_FILTERS';
export const FETCH_FILTERS_PENDING = 'FETCH_FILTERS_PENDING';
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS';
export const FETCH_FILTERS_ERROR = 'FETCH_FILTERS_ERROR';
export const SHOW_NAV_FILTERS = 'SHOW_NAV_FILTERS'
//misc
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const SHOW_CTXMENU = 'SHOW_CTXMENU';
export const SHOW_USEROPTIONS ='SHOW_USEROPTIONS';
export const LARGE_SIDEBAR = 'LARGE_SIDEBAR';
export const MANAGEMENT_ACCORDION_OPEN = 'MANAGEMENT_ACCORDION_OPEN';

//maps
export const FETCH_MAPS_PENDING = 'FETCH_MAPS_PENDING';
export const FETCH_MAPS_SUCCESS = 'FETCH_MAPS_SUCCESS';
export const FETCH_MAPS_ERRORS = 'FETCH_MAPS_ERRORS';
export const MAP_SERVER_REMOVED = 'MAP_SERVER_REMOVED';
export const RESET_MAPS = 'RESET_MAPS';
export const RESET_MAPS_ERRORS = 'RESET_MAPS_ERRORS';
export const RESET_SERVER_MAPS = 'RESET_SERVER_MAPS';

//gps-maps
export const MAP_SHOULD_ZOOM = 'MAP_SHOULD_ZOOM';

//const
export const ARTECO_CAM_CODE = 1;
export const ARTECO_PER_CODE = 0;
export const ARTECO_SER_CODE = 2;
export const CAMERA_REGEX = `_${ARTECO_CAM_CODE}_`;
export const PERIPHERAL_REGEX = `_${ARTECO_PER_CODE}_`;

//search

export const FETCH_RECORDING_PENDING    = 'FETCH_RECORDING_PENDING';
export const FETCH_RECORDING_SUCCESS    = 'FETCH_RECORDING_SUCCESS';
export const FETCH_RECORDING_ERROR      = 'FETCH_RECORDING_ERROR';
export const RESET_RECORDINGS_ERRORS    = 'RESET_RECORDINGS_ERRORS';
export const TIMELINE_MARKER            = 'TIMELINE_MARKER';
export const RESET_RECORDINGS            = 'RESET_RECORDINGS';
export const REFRESH_RECORDING_EVTS            = 'REFRESH_RECORDING_EVTS';
export const UPDATE_RECORDING_EVENT            = 'UPDATE_RECORDING_EVENT';
export const UPDATE_RECORDING_EVENTS            = 'UPDATE_RECORDING_EVENTS';


//build data
export const  FETCH_BUILD_PENDING = 'FETCH_BUILD_PENDING';
export const  FETCH_BUILD_SUCCESS = 'FETCH_BUILD_SUCCESS';
export const  FETCH_BUILD_ERROR = 'FETCH_BUILD_ERROR';

//playlist
export const FETCH_PLAYLISTS_PENDING = 'FETCH_PLAYLISTS_PENDING';
export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS';
export const FETCH_PLAYLISTS_ERROR = 'FETCH_PLAYLISTS_ERROR';
export const FETCH_PLAYLIST_PENDING = 'FETCH_PLAYLIST_PENDING';
export const FETCH_PLAYLIST_SUCCESS = 'FETCH_PLAYLIST_SUCCESS';
export const FETCH_PLAYLIST_ERROR = 'FETCH_PLAYLIST_ERROR';
export const SHOW_TIME_SEARCH_PANEL = 'SHOW_TIME_SEARCH_PANEL';
export const SET_TIMELINE_MODE = 'SET_TIMELINE_MODE';
export const SAY_IM_READY = 'SAY_IM_READY';
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const RESET_PLAYLISTS = 'RESET_PLAYLISTS';
export const SET_SYNC_MODE = 'SET_SYNC_MODE';
export const EXPORT_IS_READY = 'EXPORT_IS_READY';
export const EXPORT_IS_PENDING = 'EXPORT_IS_PENDING';
export const EXPORT_IS_DELETED = 'EXPORT_IS_DELETED';
export const SET_EXPORT_INTERVAL = 'SET_EXPORT_INTERVAL';
export const RESET_EXPORT_INFO = 'RESET_EXPORT_INFO';
export const TOGGLE_TIMELINE_VISIBILITY = 'TOGGLE_TIMELINE_VISIBILITY';
export const SET_EDGE_PLAYING = 'SET_EDGE_PLAYING';
export const RESET_PLAYLISTS_ERRORS_BY_ARTECOID = 'RESET_PLAYLISTS_ERRORS_BY_ARTECOID';

// Config

export const FETCH_CONFIG_PENDING = 'FETCH_CONFIG_PENDING';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';  
export const FETCH_CONFIG_ERRORS = 'FETCH_CONFIG_ERRORS';
export const FETCH_CONFIG_RESET = 'FETCH_CONFIG_RESET';
export const WEBGL_SUPPORT_UPDATE = 'WEBGL_SUPPORT_UPDATE';

//Downloads
export const SHOW_DOWNLOADS = 'SHOW_DOWNLOADS';
export const FETCH_DOWNLOADS_PENDING = 'FETCH_DOWNLOADS_PENDING';
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS';
export const FETCH_DOWNLOADS_ERROR = 'FETCH_DOWNLOADS_ERROR';
export const DOWNLOAD_READY = 'DOWNLOAD_READY';
export const DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS';
export const DOWNLOAD_DELETING = 'DOWNLOAD_DELETING';
export const DOWNLOAD_ABORTING = 'DOWNLOAD_ABORTING';
export const DOWNLOAD_DELETED = 'DOWNLOAD_DELETED';
export const DOWNLOAD_UPDATE_WATCHLIST = 'DOWNLOAD_UPDATE_WATCHLIST';
export const DOWNLOAD_UPDATE_DOWNLOADLIST = 'DOWNLOAD_UPDATE_DOWNLOADLIST';
export const DOWNLOAD_RESET_ERRORS = 'DOWNLOAD_RESET_ERRORS';
export const DOWNLOAD_UPDATE = 'DOWNLOAD_UPDATE';
export const DOWNLOAD_SSE_NOT_AVAILABLE = 'DOWNLOAD_SSE_NOT_AVAILABLE';
export const FILE_IS_DOWNLOADING = 'FILE_IS_DOWNLOADING';
export const FILE_DOWNLOAD_PROGRESS = 'FILE_DOWNLOAD_PROGRESS';


//User Preferences
export const FETCH_USERPREFS_PENDING = 'FETCH_USERPREFS_PENDING';
export const FETCH_USERPREFS_SUCCESS = 'FETCH_USERPREFS_SUCCESS';
export const FETCH_USERPREFS_ERROR = 'FETCH_USERPREFS_ERROR';
export const USERPREFS_UPDATED = 'USERPREFS_UPDATED';
export const ENDPOINT_CHECK_RESULT = 'ENDPOINT_CHECK_RESULT';
export const ENDPOINT_CHECKING = 'ENDPOINT_CHECKING';
export const EVENTS_TABLE_MODE = 'EVENTS_TABLE_MODE';
export const ACTIVE_TAB = 'ACTIVE_TAB';


//Chat
export const ADD_TO_CALL = 'ADD_TO_CALL';
export const ADD_TO_CHAT = 'ADD_TO_CHAT';
export const REMOVE_FROM_CHAT = 'REMOVE_FROM_CHAT';
export const CLOSE_CALL = 'CLOSE_CALL';
export const SHOW_CALL_PANEL = 'SHOW_CALL_PANEL';
export const SHOW_CHAT_PANEL = 'SHOW_CHAT_PANEL';
export const SHOW_CHAT_CONTAINER = 'SHOW_CHAT_CONTAINER';
export const SOMEONE_IS_CALLING = 'SOMEONE_IS_CALLING';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const MESSAGE_READ = 'MESSAGE_READ';
export const ACTIVATE_LAST_CHAT = 'ACTIVATE_LAST_CHAT';
export const ACTIVATE_CALL_CHAT = 'ACTIVATE_CALL_CHAT';
export const FETCH_CHAT_MSGS_PENDING = 'FETCH_CHAT_MSGS_PENDING';
export const FETCH_CHAT_MSGS_SUCCESS = 'FETCH_CHAT_MSGS_SUCCESS';
export const FETCH_CHAT_MSGS_ERROR = 'FETCH_CHAT_MSGS_ERROR';
export const CHAT_DELETED = 'CHAT_DELETED';
export const SHOW_CHAT_LIST = 'SHOW_CHAT_LIST';

// domes
export const FETCH_DOMES_PENDING = 'FETCH_DOMES_PENDING';
export const FETCH_DOMES_SUCCESS = 'FETCH_DOMES_SUCCESS';
export const RESET_DOMES = 'RESET_DOMES';
export const FETCH_DOMES_ERRORS = 'FETCH_DOMES_ERRORS';
export const DOME_SERVER_REMOVED = 'DOME_SERVER_REMOVED';
export const RESET_DOMES_ERRORS = 'RESET_DOMES_ERRORS';
export const RESET_SERVER_DOMES = 'RESET_SERVER_DOMES';

// Offline
export const SET_OFFLINE = 'SET_OFFLINE';
//features
export const SHOW_ADD_SERVER_POPUP = 'SHOW_ADD_SERVER_POPUP';
export const SHOW_ADD_LAYOUT_POPUP = 'SHOW_ADD_LAYOUT_POPUP';
export const SHOW_EDIT_LAYOUT_POPUP = 'SHOW_EDIT_LAYOUT_POPUP';
export const SHOW_SHARE_LAYOUT_POPUP = 'SHOW_SHARE_LAYOUT_POPUP';
export const SHOW_EDIT_SERVER_POPUP = 'SHOW_EDIT_SERVER_POPUP';
export const SHOW_POPUP_PROPERTIES_POPUP = 'SHOW_POPUP_PROPERTIES_POPUP';
export const SHOW_EXPORT_FEATURES_POPUP = 'SHOW_EXPORT_FEATURES_POPUP';
export const SHOW_ONVIF_DATA_POPUP = 'SHOW_ONVIF_DATA_POPUP';
export const SHOW_GENERATE_EVENT_POPUP = 'SHOW_GENERATE_EVENT_POPUP';
export const CLOSE_GENERATE_EVENT_POPUP = 'CLOSE_GENERATE_EVENT_POPUP';
export const SHOW_THUMB_POPUP = 'SHOW_THUMB_POPUP';
export const THUMB_POPUP_ENHANCING_IS_PENDING = 'THUMB_POPUP_ENHANCING_IS_PENDING';
export const THUMB_POPUP_ENHANCING_IS_READY = 'THUMB_POPUP_ENHANCING_IS_READY';
export const CLOSE_SITES_INFORMATION_POPUP = "CLOSE_SITES_INFORMATION_POPUP";
export const SHOW_SITES_INFORMATION_POPUP = "SHOW_SITES_INFORMATION_POPUP";
export const SHOW_COMING_SOON_POPUP = "SHOW_COMING_SOON_POPUP";
export const RESET_THUMB_DATA = 'RESET_THUMB_DATA';

//Tags
export const FETCH_TAGS_PENDING = 'FETCH_TAGS_PENDING';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';
export const TAGS_ERROR = 'TAGS_ERROR';
export const TAGS_UPDATED = 'TAGS_UPDATED';
export const TAGS_REMOVE = 'TAGS_REMOVE';

// Update app
export const UPDATE_APP = 'UPDATE_APP';

// Listener mgmt
export const ADD_NEW_LISTENER = 'ADD_NEW_LISTENER';
export const REMOVE_NEW_LISTENER = 'REMOVE_NEW_LISTENER';

//devices
export const PTZ_IS_CHANGING = 'PTZ_IS_CHANGING';
export const CHANNEL_IS_SET = 'CHANNEL_IS_SET';
export const CHANNEL_IS_SETTING = 'CHANNEL_IS_SETTING';
export const CHANNEL_ADD_PROPERTY = "CHANNEL_ADD_PROPERTY";
export const CHANNEL_UPDATE = "CHANNEL_UPDATE";
export const CHANNEL_ADD = "CHANNEL_ADD";
export const CHANNEL_REMOVE = "CHANNEL_REMOVE"
export const LAYOUT_CHANNEL_REMOVE = "LAYOUT_CHANNEL_REMOVE"
export const UPDATE_DEVICE="UPDATE_DEVICE"

export const FETCH_PLUGINS_PENDING = 'FETCH_PLUGINS_PENDING';
export const FETCH_PLUGINS_SUCCESS = 'FETCH_PLUGINS_SUCCESS';
export const FETCH_PLUGINS_ERROR = 'FETCH_PLUGINS_ERROR';
export const PLUGINS_UPDATED = 'PLUGINS_UPDATED';

//linked peripheral
export const LINKED_PERIPHERALS= 'LINKED_PERIPHERALS'
export const CLOSE_LINKED_PERIPHERALS = 'CLOSE_LINKED_PERIPHERALS'

//meta
export const FETCH_META_PENDING = 'FETCH_META_PENDING';
export const FETCH_META_SUCCESS = 'FETCH_META_SUCCESS';
export const FETCH_META_ERROR = 'FETCH_META_ERROR';

// Server Camera
export const ADD_NEW_CHANNEL_PENDING = "ADD_NEW_CHANNEL_PENDING";
export const ADD_NEW_CHANNEL_SUCSESS = "ADD_NEW_CHANNEL_SUCSESS";
export const ADD_NEW_CHANNEL_ERROR = "ADD_NEW_CHANNEL_ERROR";
export const SET_EDGE_MODE = "SET_EDGE_MODE";

export const FETCH_ADDITIONAL_PROPERTIES_PENDING = 'FETCH_ADDITIONAL_PROPERTIES_PENDING';
export const FETCH_ADDITIONAL_PROPERTIES_SUCCESS = 'FETCH_ADDITIONAL_PROPERTIES_SUCCESS';
export const FETCH_SERVER_ADDITIONAL_PROPERTIES_SUCCESS = 'FETCH_SERVER_ADDITIONAL_PROPERTIES_SUCCESS';
export const FETCH_ADDITIONAL_PROPERTIES_ERROR = 'FETCH_ADDITIONAL_PROPERTIES_ERROR';
export const FETCH_ADDITIONAL_PROPERTIES_UPDATED = 'FETCH_ADDITIONAL_PROPERTIES_UPDATED';



// U-Follow
export const FETCH_UFOLLOW_SEQUENCE_LIST = "FETCH_UFOLLOW_SEQUENCE_LIST";
export const FETCH_UFOLLOW_CHANGE_SELECTED_SEQUENCE = "FETCH_UFOLLOW_CHANGE_SELECTED_SEQUENCE";
export const FETCH_UFOLLOW_CHANGE_SELECTED_SITE = "FETCH_UFOLLOW_CHANGE_SELECTED_SITE";
export const GET_UFOLLOW_SEQUENCE = "GET_UFOLLOW_SEQUENCE";
export const RESET_UFOLLOW_SEQUENCE= "RESET_UFOLLOW_SEQUENCE";
export const RESET_UFOLLOW_SEQUENCE_LIST= "RESET_UFOLLOW_SEQUENCE_LIST";
export const UPDATE_SEQUENCE = "UPDATE_SEQUENCE";
export const RESET_SEQUENCE = "RESET_SEQUENCE";
export const TOGGLE_UFOLLOW_CAMERA_PICKER = "TOGGLE_UFOLLOW_CAMERA_PICKER";
export const TOGGLE_CREATE_SEQUENCE_POPUP = "TOGGLE_CREATE_SEQUENCE_POPUP";
export const TOGGLE_SEQUENCE_TUITORAIL_POPUP= "TOGGLE_SEQUENCE_TUITORAIL_POPUP"
export const ERROR_UFOLLOW = "ERROR_UFOLLOW";
export const LOADING_UFOLLOW = "LOADING_UFOLLOW";
// AUDITING
export const RESET_AUDITINGS_ERRORS = "RESET_AUDITINGS_ERRORS";
export const FETCH_AUDITING_PENDING = "FETCH_AUDITING_PENDING";
export const RESET_AUDITINGS = "RESET_AUDITINGS";
export const FETCH_AUDITING_SUCCESS = "FETCH_AUDITING_SUCCESS";
export const FETCH_AUDITING_ERROR = "FETCH_AUDITING_ERROR";

// INTEGRATIONS
export const UPDATE_INTEGRATIONS = "UPDATE_INTEGRATIONS";
export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";
export const SELECT_INTEGRATION = "SELECT_INTEGRATION";
export const DESELECT_INTEGRATION = "DESELECT_INTEGRATION";
export const REMOVE_LINKED_ARTECO_ID = "REMOVE_LINKED_ARTECO_ID";

export const RESET_STATE_ON_OFFLINE = "RESET_STATE_ON_OFFLINE";
