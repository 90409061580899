import axios from 'axios';
import {info, logger} from '../helpers/logger';
import { appPaths } from '../config/paths';

import {
  SET_ERRORS,
  SHOW_SIDEBAR,
  SHOW_CTXMENU,
  SHOW_USEROPTIONS,
  LARGE_SIDEBAR,
  SHOW_EVENTS,
  SHOW_THUMBNAIL_SEARCH,
  LOCK_EVENTS,
  LOCK_THUMBS,
  LARGE_EVENTS,
  LARGE_THUMBNAIL,
  SHOW_PICTURES,
  SHOW_SEARCH,
  SHOW_CALENDAR,
  SHOW_REC_PANEL,
  SHOW_IN_POPUP,
  HIDE_POPUP,
  CLEAN_DONE,
  RESET_EVENTS,
  EVENTS_TABLE_MODE,
  MARK_FOR_REMOVAL,
  EVENTS_BATCH_ADDED,
  SHOW_GENERATE_EVENT_POPUP,
  CLOSE_GENERATE_EVENT_POPUP,
  EVENTS_PANEL_MODE,
  EVENTS_PANEL_CELL_SIZE,
  EVENTS_PANEL_CELL_SIZE_LARGE,
  EVENTS_SORT_BY_EVENT_TIME,
  EVENTS_SORT_AC_ORDER,
  MANAGEMENT_ACCORDION_OPEN,
  EVENT_TABLE_DATA,
  ADD_NOTE,
  BLUR_EVENT,
  UPDATE_EVENT_THUMBNAIL,
  SET_ACTIVE_INDEX,
  SET_THUMB_TIMESTAMP,
  UPDATE_TRUTHY_STATUS
} from './types';

function hidePanel(PANEL, dispatch) {
  dispatch({
    type: `SHOW_${PANEL}`,
    payload: false
  })
}



export const toggleEventPanel = (showEvents) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventPanelVisible', showEvents);
  }
  dispatch({
    type: SHOW_EVENTS,
    payload: showEvents
  })
}
export const toggleThumbPanel = (showThumbs) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('thumbPanelVisible', showThumbs);
  }
  dispatch({
    type: SHOW_THUMBNAIL_SEARCH,
    payload: showThumbs
  })
}

export const toggleEventPanelLock = (lockEvents) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventPanelLocked', lockEvents);
  }
  dispatch({
    type: LOCK_EVENTS,
    payload: lockEvents
  })
}
export const toggleThumbPanelLock = (lockThumbs) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('thumbPanelLocked', lockThumbs);
  }
  dispatch({
    type: LOCK_THUMBS,
    payload: lockThumbs
  })
}
export const toggleEventPanelLarge = (largeEvents) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventPanelLarge', largeEvents);
  }
  dispatch({
    type: LARGE_EVENTS,
    payload: largeEvents
  })
}
export const toggleThumbPanelLarge = (largeThumbs) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('thumbPanelLarge', largeThumbs);
  }
  dispatch({
    type: LARGE_THUMBNAIL,
    payload: largeThumbs
  })
}

export const toggleEventPanelMode = (eventPanelMode) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventPanelMode', eventPanelMode);
  }
  dispatch({
    type: EVENTS_PANEL_MODE,
    payload: eventPanelMode
  })
}

export const toggleEventPanelCellSize = (eventPanelCellSize, isLarge = false) => dispatch => {
  if (global.localStorage) {
    const item = isLarge ? 'eventPanelCellSizeLarge' : 'eventPanelCellSize';
    global.localStorage.setItem(item, eventPanelCellSize);
  }
  const type = isLarge ? EVENTS_PANEL_CELL_SIZE_LARGE : EVENTS_PANEL_CELL_SIZE;
  dispatch({
    type: type,
    payload: eventPanelCellSize
  })
}

export const toggleCalendar = (showCalendar) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_CALENDAR,
    payload: showCalendar
  })
  if(showCalendar) {
    hidePanel('FILTERS', dispatch);
    hidePanel('SEARCH', dispatch);
    hidePanel('REC_PANEL', dispatch);        
  }  
}

export const toggleRecSearchPanel = (showRecPanel) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_REC_PANEL,
    payload: showRecPanel
  })
  if(showRecPanel) {
    hidePanel('CALENDAR', dispatch);        
  }  
}

export const togglePictures = (showPictures) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_PICTURES,
    payload: showPictures
  })
}

export const toggleSearch = (showSearch) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_SEARCH,
    payload: showSearch
  })
  if(showSearch) {
    hidePanel('FILTERS', dispatch);
    hidePanel('REC_PANEL', dispatch);
    hidePanel('CALENDAR', dispatch);        
  }  
}

export const showInPopup = (popupData) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_IN_POPUP,
    payload: popupData
  })
}

export const hidePopup = () => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: HIDE_POPUP
  })
}

export const toggleCtxMenu = (showCtxMenu) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('ctxMenuVisible', showCtxMenu);
  }
  dispatch({
    type: SHOW_CTXMENU,
    payload: showCtxMenu
  })
}

export const toggleUserOptions =(showUserOptions) => dispatch => { 
  if (global.localStorage) {
    global.localStorage.setItem('userOptionsVisible', showUserOptions);
  }
  dispatch({
    type: SHOW_USEROPTIONS,
    payload: showUserOptions
    
  })
}

export const toggleSidebar = (showSidebar) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('sidebarVisible', showSidebar);
  }
  dispatch({
    type: SHOW_SIDEBAR,
    payload: showSidebar
  })
}

export const enlargeSidebar = (largeSidebar) => dispatch => {  
  //We'll exclude pages from the extended sidebar by adding them to the exceptions array.
  //Then call the enlargeSidebar function with 'false' as an argument when mounting that page.
  const exceptions = [appPaths.myaccount]
  if(!exceptions.includes(window.location.pathname)){
    //storing the extended state on session store if we are not on user settings
    sessionStorage.setItem('sidebarExtendedException', largeSidebar);
  }
  dispatch({
    type: LARGE_SIDEBAR,
    payload: largeSidebar
  })
}

export const toggleManagementAccordion = (managementAccordionOpen) => dispatch => {  
  dispatch({
    type: MANAGEMENT_ACCORDION_OPEN,
    payload: managementAccordionOpen
  })
}

export const addEvent = (newEvent) => dispatch => {
  axios
    .post('/api/events/insert', newEvent)
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}

export const eventsBatchUpdate = (events, limit) => dispatch => {
  dispatch({
    type: EVENTS_BATCH_ADDED,
    payload: {
      events: events,
      limit: limit
    }
  })
}

export const addEvents = (newEvents) => dispatch => {  
  axios
    .post('/api/events/insert-batch', newEvents)   
    // .then(res => {
    //   dispatch({
    //     type: TAG_LAST_TIMESTAMP,
    //     payload: newEvents
    //   });
    // }) 
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}


export const cleanEvents = (configuration) => dispatch => {  

  axios
    .post('/api/events/delete-procedure', configuration)  
    .then(res => {
      let deleteCount = res.data.deletedCount;
       logger(info,"clean done, events deleted -> " + deleteCount);    
      //delete done, tagging server
      if(res.status === 200) {
        axios
          .post('/api/servers/tag', configuration)
          .then(res => {
             logger(info, 'eventDeleteAction', res);
            configuration = {
              ...configuration,
              deleteCount 
            }  
            dispatch({
              type: CLEAN_DONE,
              payload: configuration
            });            
          })
          .catch(err => dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          }))
      }
    })   
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}


export const resetEvents = () => dispatch => {
  dispatch({
    type: RESET_EVENTS
  })
}
export const updateEventThumbnail = (eventId, thumbnailUri) => ({
  type: UPDATE_EVENT_THUMBNAIL, //leaving this here for future use, changing the thumbnail across
  payload: {
    eventId,
    thumbnailUri
  }
});
export const setActiveIndex = (eventId, index) => ({
  type: SET_ACTIVE_INDEX,
  payload: { eventId, index },
});

export const setThumbTimestamp = (artecoEventId, thumbTimestamp) => ({
  type: SET_THUMB_TIMESTAMP,
  payload: { artecoEventId, thumbTimestamp },
});


export const showEventPopup = (eventId) => dispatch => {
  axios
    .post('/api/events/get-by-id', {
      artecoEventId: eventId
    })
    .then(eventData => {
      const event = eventData.data;
      if(event) {
        const popupData = {
          serverCodename: event.serverCodename,
          artecoId: event.artecoId,
          events: [event],
          configuration: {
            style: 'complete',
            show: {
              snapshot: true,
              eventData: true,
              video: true,
              notes: true,
              hideExport: true
            }
          }
        }
          
        dispatch(showInPopup(popupData));
      }
    })
    .catch(err => dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    }))
}
export const setEventsViewMode = (viewMode) => dispatch => {
  //viewMode could be 'live' or 'history';

  dispatch({
    type: EVENTS_TABLE_MODE,
    payload: viewMode
  })
}

export const showGenerateEventPopup = (popupInfo) => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: SHOW_GENERATE_EVENT_POPUP,
    payload: popupInfo
  })
}

export const closeGenerateEventPopup = () => dispatch => {
  //we could save this setting in LocalStorage
  dispatch({
    type: CLOSE_GENERATE_EVENT_POPUP,
  })
}

export const toggleSortByEventime = (sortByEventime) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventsSortByEventTime', sortByEventime);
  }
  dispatch({
    type: EVENTS_SORT_BY_EVENT_TIME,
    payload: sortByEventime
  })
}

export const toggleEventsSortOrderAC = (sortOrderAC) => dispatch => {
  if (global.localStorage) {
    global.localStorage.setItem('eventsSortOrderAC', sortOrderAC);
  }
  dispatch({
    type: EVENTS_SORT_AC_ORDER,
    payload: sortOrderAC
  })
}

export const addEventTableData = (eventTableData) => dispatch => {

  dispatch({
    type:   EVENT_TABLE_DATA,
    payload: eventTableData
  })
}
export const addNote = (note) => ({
  type: ADD_NOTE,
  payload: note,
});

export const toggleBlurEvent = (blurEvent) => dispatch =>{
  if (global.localStorage) {
    global.localStorage.setItem('blurEvent', blurEvent);
  }
  dispatch({
    type: BLUR_EVENT,
    payload: blurEvent
  })
}
export const updateTruthyStatus = (eventId, status) => dispatch => {
  dispatch({
    type: UPDATE_TRUTHY_STATUS,
    payload: { eventId, status }
  });
};

